import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities, removeEntity } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDES/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDES/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDES/LIST_FAILURE',
  TOTAL_MEMBERS_REQUEST: 'CARE_GUIDES/TOTAL_MEMBERS_REQUEST',
  TOTAL_MEMBERS_SUCCESS: 'CARE_GUIDES/TOTAL_MEMBERS_SUCCESS',
  TOTAL_MEMBERS_FAILURE: 'CARE_GUIDES/TOTAL_MEMBERS_FAILURE',
  READ_REQUEST: 'CARE_GUIDES/READ_REQUEST',
  READ_SUCCESS: 'CARE_GUIDES/READ_SUCCESS',
  READ_FAILURE: 'CARE_GUIDES/READ_FAILURE',
  UPDATE_REQUEST: 'CARE_GUIDES/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARE_GUIDES/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARE_GUIDES/UPDATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDES/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDES/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDES/PATCH_FAILURE',
  PDF_REQUEST: 'CARE_GUIDES/PDF_REQUEST',
  PDF_SUCCESS: 'CARE_GUIDES/PDF_SUCCESS',
  PDF_FAILURE: 'CARE_GUIDES/PDF_FAILURE',
  FORM_DESTROY: 'CARE_GUIDES/FORM_DESTROY',
  SET_ACTIVE: 'CARE_GUIDES/SET_ACTIVE',
  SET_FIRST_LOAD: 'CARE_GUIDES/SET_FIRST_LOAD',
  SET_CARE_GUIDE_ACCESS: 'CARE_GUIDES/SET_CARE_GUIDE_ACCESS',
  CREATE_SEGMENT_REQUEST: 'CARE_GUIDES/CREATE_SEGMENT_REQUEST',
  CREATE_SEGMENT_SUCCESS: 'CARE_GUIDES/CREATE_SEGMENT_SUCCESS',
  CREATE_SEGMENT_FAILURE: 'CARE_GUIDES/CREATE_SEGMENT_FAILURE',
  UPDATE_SEGMENT_REQUEST: 'CARE_GUIDES/UPDATE_SEGMENT_REQUEST',
  UPDATE_SEGMENT_SUCCESS: 'CARE_GUIDES/UPDATE_SEGMENT_SUCCESS',
  UPDATE_SEGMENT_FAILURE: 'CARE_GUIDES/UPDATE_SEGMENT_FAILURE',
  DELETE_SEGMENT_REQUEST: 'CARE_GUIDES/DELETE_SEGMENT_REQUEST',
  DELETE_SEGMENT_SUCCESS: 'CARE_GUIDES/DELETE_SEGMENT_SUCCESS',
  DELETE_SEGMENT_FAILURE: 'CARE_GUIDES/DELETE_SEGMENT_FAILURE',
  PDF_SEGMENT_REQUEST: 'CARE_GUIDES/PDF_SEGMENT_REQUEST',
  PDF_SEGMENT_SUCCESS: 'CARE_GUIDES/PDF_SEGMENT_SUCCESS',
  PDF_SEGMENT_FAILURE: 'CARE_GUIDES/PDF_SEGMENT_FAILURE',
  UPDATE_IMAGE_REQUEST: 'CARE_GUIDES/UPDATE_IMAGE_REQUEST',
  UPDATE_IMAGE_SUCCESS: 'CARE_GUIDES/UPDATE_IMAGE_SUCCESS',
  UPDATE_IMAGE_FAILURE: 'CARE_GUIDES/UPDATE_IMAGE_FAILURE',
  DELETE_IMAGE_REQUEST: 'CARE_GUIDES/DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'CARE_GUIDES/DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'CARE_GUIDES/DELETE_IMAGE_FAILURE',
  SET_ACTIVE_PAGE: 'CARE_GUIDES/SET_ACTIVE_PAGE',
  CARE_GUIDE_FORM_DESTROY: 'CARE_GUIDES/FORM_DESTROY',
  CARE_GUIDES_ALL_IDS_REQUEST: 'CARE_GUIDES_ALL_IDS_REQUEST',
  CARE_GUIDES_ALL_IDS_SUCCESS: 'CARE_GUIDES_ALL_IDS_SUCCESS',
  CARE_GUIDES_ALL_IDS_FAILURE: 'CARE_GUIDES_ALL_IDS_FAILURE',
  CARE_GUIDES_CSV_DOWNLOAD_REQUEST: 'CARE_GUIDES_CSV_DOWNLOAD_REQUEST',
  CARE_GUIDES_CSV_DOWNLOAD_SUCCESS: 'CARE_GUIDES_CSV_DOWNLOAD_SUCCESS',
  CARE_GUIDES_CSV_DOWNLOAD_FAILURE: 'CARE_GUIDES_CSV_DOWNLOAD_FAILURE',
  CARE_GUIDES_PARTNERSHIP_LIST_REQUEST: 'CARE_GUIDES_PARTNERSHIP_LIST_REQUEST',
  CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS: 'CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS',
  CARE_GUIDES_PARTNERSHIP_LIST_FAILURE: 'CARE_GUIDES_PARTNERSHIP_LIST_FAILURE',
};

export function careGuideListRequest(page, limit, order, filter) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideListRequest(${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideListSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideListFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    error: error,
  };
}

export function careGuideReadRequest(id) {
  Logger.log('debug', `[state.careGuides.actions] careGuideReadRequest(${id})`);
  return {
    type: TYPES.READ_REQUEST,
    id: id,
  };
}

export function careGuideReadSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideReadSuccess(%j)`,
    data
  );
  return {
    type: TYPES.READ_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideReadFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideReadFailure(%j)`,
    error
  );
  return {
    type: TYPES.READ_FAILURE,
    error: error,
  };
}

export function careGuideUpdateRequest(id, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateRequest(${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_REQUEST,
  };
}

export function careGuideUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPDATE_FAILURE,
    error: error,
  };
}

export function careGuidePatchRequest(id, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePatchRequest(${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
  };
}

export function careGuidePatchSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePatchSuccess(%j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuidePatchFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePatchFailure(%j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    error: error,
  };
}

export function careGuidePDFRequest(careGuideId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePDFRequest(${careGuideId})`
  );
  return {
    type: TYPES.PDF_REQUEST,
    careGuideId: careGuideId,
  };
}

export function careGuidePDFSuccess(careGuideId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePDFSuccess(${careGuideId})`
  );
  return {
    type: TYPES.PDF_SUCCESS,
    careGuideId: careGuideId,
    receivedAt: Date.now(),
  };
}

export function careGuidePDFFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePDFFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.PDF_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideFormDestroy(%j)`,
    formState
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function careGuideSetActive(id) {
  Logger.log('debug', `[state.careGuides.actions] careGuideSetActive(${id})`);
  return {
    type: TYPES.SET_ACTIVE,
    id: id,
  };
}

export function careGuideSetFirstLoad(firstLoad) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSetFirstLoad(${firstLoad})`
  );
  return {
    type: TYPES.SET_FIRST_LOAD,
    firstLoad: firstLoad,
  };
}

export function careGuideSetAccess(data) {
  Logger.log('debug', `[state.careGuides.actions] careGuideSetAccess(${data})`);
  return {
    type: TYPES.SET_CARE_GUIDE_ACCESS,
    access_role_admin_in_care_guide: data.access_role_admin_in_care_guide,
  };
}

export function careGuideSegmentCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SEGMENT_REQUEST,
    careGuideId: careGuideId,
  };
}

export function careGuideSegmentCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SEGMENT_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideSegmentCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_SEGMENT_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideSegmentUpdateRequest(careGuideId, segmentId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentUpdateRequest(${careGuideId}, ${segmentId}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SEGMENT_REQUEST,
  };
}

export function careGuideSegmentUpdateSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentUpdateSuccess(%j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SEGMENT_SUCCESS,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideSegmentUpdateFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentUpdateFailure(%j)`,
    error
  );
  return {
    type: TYPES.UPDATE_SEGMENT_FAILURE,
    error: error,
  };
}

export function careGuideSegmentDeleteRequest(careGuideId, segmentId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentDeleteRequest(${careGuideId}, ${segmentId})`
  );
  return {
    type: TYPES.DELETE_SEGMENT_REQUEST,
    careGuideId: careGuideId,
    id: segmentId,
  };
}

export function careGuideSegmentDeleteSuccess(careGuideId, segmentId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentDeleteSuccess(${careGuideId}, ${segmentId})`
  );
  return {
    type: TYPES.DELETE_SEGMENT_SUCCESS,
    careGuideId: careGuideId,
    id: segmentId,
  };
}

export function careGuideSegmentDeleteFailure(careGuideId, segmentId, error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentDeleteFailure(${careGuideId}, ${segmentId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_SEGMENT_FAILURE,
    careGuideId: careGuideId,
    id: segmentId,
    error: error,
  };
}

export function careGuideSegmentPDFRequest(careGuideId, segmentId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentPDFRequest(${careGuideId}, ${segmentId})`
  );
  return {
    type: TYPES.PDF_SEGMENT_REQUEST,
    careGuideId: careGuideId,
    segmentId: segmentId,
  };
}

export function careGuideSegmentPDFSuccess(careGuideId, segmentId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentPDFSuccess(${careGuideId}, ${segmentId})`
  );
  return {
    type: TYPES.PDF_SEGMENT_SUCCESS,
    careGuideId: careGuideId,
    segmentId: segmentId,
    receivedAt: Date.now(),
  };
}

export function careGuideSegmentPDFFailure(careGuideId, segmentId, error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideSegmentPDFFailure(${careGuideId}, ${segmentId}, %j)`,
    error
  );
  return {
    type: TYPES.PDF_SEGMENT_FAILURE,
    careGuideId: careGuideId,
    segmentId: segmentId,
    error: error,
  };
}

export function careGuideUpdateImageRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateImageRequest(${careGuideId}, ###)`,
    data
  );
  return {
    type: TYPES.UPDATE_IMAGE_REQUEST,
    careGuideId: careGuideId,
  };
}

export function careGuideUpdateImageSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateImageSuccess(${careGuideId}, ###)`,
    data
  );
  return {
    type: TYPES.UPDATE_IMAGE_SUCCESS,
    careGuideId: careGuideId,
  };
}

export function careGuideUpdateImageFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideUpdateImageFailure(###)`,
    error
  );
  return {
    type: TYPES.UPDATE_IMAGE_FAILURE,
    error: error,
  };
}

export function careGuideDeleteImageRequest(careGuideId) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideDeleteImageRequest(${careGuideId})`
  );
  return {
    type: TYPES.DELETE_IMAGE_REQUEST,
    careGuideId: careGuideId,
  };
}

export function careGuideDeleteImageSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideDeleteImageSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.DELETE_IMAGE_SUCCESS,
    careGuideId: data.careGuideId,
  };
}

export function careGuideDeleteImageFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuideDeleteImageFailure(%j)`,
    error
  );
  return {
    type: TYPES.DELETE_IMAGE_FAILURE,
    error: error,
  };
}

export function careGuidePageSetActive(page) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidePageSetActive(${page})`
  );
  return {
    type: TYPES.SET_ACTIVE_PAGE,
    page: page,
  };
}

export function careGuidesAllIdsRequest() {
  Logger.log('debug', `[state.careGuides.actions] careGuidesAllIdsRequest()`);
  return {
    type: TYPES.CARE_GUIDES_ALL_IDS_REQUEST,
  };
}

export function careGuidesAllIdsSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidesAllIdsSuccess(%j)`,
    data
  );
  return {
    type: TYPES.CARE_GUIDES_ALL_IDS_SUCCESS,
    data: data,
  };
}

export function careGuidesAllIdsFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidesAllIdsFailure(%j)`,
    error
  );
  return {
    type: TYPES.CARE_GUIDES_ALL_IDS_FAILURE,
    error: error,
  };
}

export function careGuidesCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidesCSVDownloadRequest()`
  );
  return {
    type: TYPES.CARE_GUIDES_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuidesCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidesCSVDownloadSuccess()`
  );
  return {
    type: TYPES.CARE_GUIDES_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuidesCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuides.actions] careGuidesCSVDownloadFailure()`
  );
  return {
    type: TYPES.CARE_GUIDES_CSV_DOWNLOAD_FAILURE,
  };
}

export function totalMembersRequest(partnershipId, filter) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] totalMembersRequest(%j)`,
    filter
  );
  return {
    type: TYPES.TOTAL_MEMBERS_REQUEST,
    filter: filter,
    partnershipId: partnershipId,
  };
}

export function totalMembersSuccess(data) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] totalMembersSuccess(%j)`,
    data
  );
  return {
    type: TYPES.TOTAL_MEMBERS_SUCCESS,
    total: data.total,
    total_filtered: data.total_filtered,
    idsFiltered: data.ids_filtered,
    receivedAt: Date.now(),
  };
}

export function totalMembersFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] totalMembersFailure(%j)`,
    error
  );
  return {
    type: TYPES.TOTAL_MEMBERS_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function careGuidesPartnershipListRequest(
  partnershipId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[careGuidesPartnership.actions] careGuidesPartnershipListRequest(${partnershipId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.CARE_GUIDES_PARTNERSHIP_LIST_REQUEST,
    page: page,
    limit: limit,
    order: order,
    partnershipId: partnershipId,
    filter: filter,
  };
}

export function careGuidesPartnershipListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[careGuidesPartnership.actions] careGuidesPartnershipListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.CARE_GUIDES_PARTNERSHIP_LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuidesPartnershipListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[careGuidesAdmin.actions] careGuideAdminListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CARE_GUIDES_PARTNERSHIP_LIST_FAILURE,
    error: error,
    partnershipId: partnershipId,
  };
}

// API THUNK ACTION CREATORS

export function loadCareGuidesPartnership(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[careGuidesPartnership.actions] loadCareGuidesPartnership(${partnershipId}, ${page}, ${limit}, ${order})`
  );

  return async function (dispatch) {
    dispatch(
      careGuidesPartnershipListRequest(
        partnershipId,
        page,
        limit,
        order,
        filter
      )
    );

    // call API
    const response = await api.getCareGuidesPartnership(
      partnershipId,
      page,
      limit,
      order,
      filter
    );
    let success = false;
    let result = [];
    // get care guides partnership list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides list admin success. Partnership ID: ${partnershipId}. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guides']),
        [schema.careGuide]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidesPartnershipListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides admin success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuidesPartnershipListSuccess(partnershipId, data));
      success = true;

      // get care guide partnership list failure
    } else {
      Logger.log(
        'info',
        `Get API care guides list failure. Page: ${page}, Limit: ${limit}, Partnership ID: ${partnershipId}.`
      );

      dispatch(
        careGuidesPartnershipListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success, result);
  };
}

export function loadCareGuides(
  page = 1,
  limit = 10,
  order = null,
  filter = null,
  partnershipId = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] loadCareGuides(${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(careGuideListRequest(page, limit, order, filter));

    // call API
    const response = await api.getCareGuides(
      page,
      limit,
      order,
      filter,
      partnershipId
    );
    let success = false;

    // get care guides list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides list success. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guides']),
        [schema.careGuide]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideListSuccess(data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guides list success [empty]. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(careGuideListSuccess(data));
      success = true;

      // get care guides list failure
    } else {
      Logger.log(
        'info',
        `Get API care guides list failure. Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(careGuideListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadTotalMembers(
  partnershipId = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] loadCareGuides(%j, ###)`,
    filter
  );

  return async function (dispatch) {
    dispatch(totalMembersRequest(partnershipId, filter));

    // call API
    const response = await api.getTotalMembers(partnershipId, filter);
    let success = false;

    // get care guides list success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide members total success.`);

      const data = {
        total: response.getIn(['data', 'total']),
        total_filtered: response.getIn(['data', 'total_filtered']),
        ids_filtered: response.getIn(['data', 'ids_filtered']),
      };

      dispatch(totalMembersSuccess(data));
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log('info', `Get API care guide members total success [empty].`);
      const data = {
        total: 0,
        total_filtered: 0,
        ids_filtered: [],
      };
      dispatch(totalMembersSuccess(data));
      success = true;
      // get care guides list failure
    } else {
      Logger.log('info', `Get API care guides  members total failure.`);
      dispatch(totalMembersFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuide(id, cb = function () {}) {
  Logger.log('debug', `[state.careGuides.actions] loadCareGuide(${id}, ###)`);

  return async function (dispatch) {
    dispatch(careGuideReadRequest(id));

    // call API
    const response = await api.getCareGuide(id);
    let success = false;

    // get care guide success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guide success. ID: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideReadSuccess(data));
      success = true;

      // get care guide failure
    } else {
      Logger.log('info', `Get API care guide failure. ID: ${id}.`);
      dispatch(careGuideReadFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuide(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] updateCareGuide(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideUpdateRequest(id, data));

    // call API
    const response = await api.putCareGuide(id, data);
    let success = false;

    // put care guide success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API care guide success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideUpdateSuccess(data));
      success = true;

      // get care guide failure
    } else {
      Logger.log('info', `PUT API care guide failure. ID: ${id}.`);
      dispatch(careGuideUpdateFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function patchCareGuide(id, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] patchCareGuide(${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuidePatchRequest(id, data));

    // call API
    const response = await api.patchCareGuide(id, data);
    let success = false;

    // patch care guide success
    if (200 === response.get('status')) {
      Logger.log('info', `PATCH API care guide success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuidePatchSuccess(data));
      success = true;

      // patch care guide failure
    } else {
      Logger.log('info', `PATCH API care guide failure. ID: ${id}.`);
      dispatch(careGuidePatchFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function createCareGuideSegment(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] createCareGuideSegment(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideSegmentCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideSegments(careGuideId, data);
    let success = false;

    // post care guide segment success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide segment success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_segment'])],
        [schema.careGuideSegment]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_segment', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSegmentCreateSuccess(careGuideId, data));
      success = true;

      // post care guide segment failure
    } else {
      Logger.log(
        'info',
        `POST API care guide segment failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideSegmentCreateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideSegment(
  careGuideId,
  segmentId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] updateCareGuide(${careGuideId}, ${segmentId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideSegmentUpdateRequest(careGuideId, segmentId, data));

    // call API
    const response = await api.putCareGuideSegment(
      careGuideId,
      segmentId,
      data
    );
    let success = false;

    // put care guide segment success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `PUT API care guide segment success. Care Guide: ${careGuideId}, Segment: ${segmentId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_segment'])],
        [schema.careGuideSegment]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_segment', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideSegmentUpdateSuccess(data));
      success = true;

      // get care guide segment failure
    } else {
      Logger.log(
        'info',
        `PUT API care guide segment failure. Care Guide: ${careGuideId}, Segment: ${segmentId}.`
      );
      dispatch(
        careGuideSegmentUpdateFailure(response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideSegment(
  careGuideId,
  segmentId,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] deleteCareGuideSegment(${careGuideId}, ${segmentId}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideSegmentDeleteRequest(careGuideId, segmentId));

    // call API
    const response = await api.deleteCareGuideSegment(careGuideId, segmentId);
    let success = false;

    // delete care guide segment success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API care guide segment success. Care Guide ID: ${careGuideId}, Segment: ${segmentId}.`
      );

      dispatch(
        removeEntity({ entityType: 'careGuideSegments', id: segmentId })
      );
      dispatch(careGuideSegmentDeleteSuccess(careGuideId, segmentId));
      success = true;

      // get care guide segment failure
    } else {
      Logger.log(
        'info',
        `DELETE API care guide segment failure. Care Guide ID: ${careGuideId}, Segment: ${segmentId}.`
      );
      dispatch(
        careGuideSegmentDeleteFailure(
          careGuideId,
          segmentId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideImage(careGuideId, data, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] updateCareGuideImage(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideUpdateImageRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideImage(careGuideId, data);
    let success = false;

    // post care guide image success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide image success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideUpdateImageSuccess(careGuideId, data));
      success = true;

      // post care guide image failure
    } else {
      Logger.log(
        'info',
        `POST API care guide image failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(careGuideUpdateImageFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function deleteCareGuideImage(careGuideId, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] deleteCareGuideImage(${careGuideId}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDeleteImageRequest(careGuideId));

    // call API
    const response = await api.deleteCareGuideImage(careGuideId);
    let success = false;

    // delete care guide image  success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `DELETE API care guide image  success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide'])],
        [schema.careGuide]
      );
      const data = {
        id: response.getIn(['data', 'care_guide', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDeleteImageSuccess(careGuideId, data));
      success = true;

      // delete care guide image  failure
    } else {
      Logger.log(
        'info',
        `DELETE API care guide image  failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(careGuideDeleteImageFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function generateCareGuidePDF(careGuideId, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] generateCareGuidePDF(${careGuideId}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidePDFRequest(careGuideId));

    // call API
    const response = await api.getCareGuidePDF(careGuideId);
    let success = false;

    // get care guide PDF success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide PDF success. Care Guide ID: ${careGuideId}.`
      );

      // trigger browser download
      const url = window.URL.createObjectURL(
        new Blob([response.get('data')], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.get('headers')['content-disposition'];
      link.setAttribute(
        'download',
        contentDisposition
          ? contentDisposition.substring(
              contentDisposition.indexOf('"') + 1,
              contentDisposition.lastIndexOf('"')
            )
          : 'care-guide.pdf'
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuidePDFSuccess(careGuideId));
      success = true;

      // get care guide PDF failure
    } else {
      Logger.log(
        'info',
        `Get API care guide PDF failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuidePDFFailure(careGuideId, response.getIn(['data', 'error']))
      );
    }

    // callback function
    cb(success);
  };
}

export function generateCareGuideSegmentPDF(
  careGuideId,
  segmentId,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] generateCareGuideSegmentPDF(${careGuideId}, ${segmentId}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideSegmentPDFRequest(careGuideId, segmentId));

    // call API
    const response = await api.getCareGuideSegmentPDF(careGuideId, segmentId);
    let success = false;

    // get care guide PDF success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide PDF success. Care Guide ID: ${careGuideId}, Segment ID: ${segmentId}.`
      );

      // trigger browser download
      const url = window.URL.createObjectURL(
        new Blob([response.get('data')], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.get('headers')['content-disposition'];
      link.setAttribute(
        'download',
        contentDisposition
          ? contentDisposition.substring(
              contentDisposition.indexOf('"') + 1,
              contentDisposition.lastIndexOf('"')
            )
          : 'care-guide.pdf'
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideSegmentPDFSuccess(careGuideId, segmentId));
      success = true;

      // get care guide PDF failure
    } else {
      Logger.log(
        'info',
        `Get API care guide PDF failure. Care Guide ID: ${careGuideId}, Segment ID: ${segmentId}.`
      );
      dispatch(
        careGuideSegmentPDFFailure(
          careGuideId,
          segmentId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuidesAllIds(
  partnership_id = null,
  filter = null,
  cb = function () {}
) {
  Logger.log('debug', `[state.careGuides.actions] loadCareGuidesAllIds()`);

  return async function (dispatch) {
    dispatch(careGuidesAllIdsRequest());

    // call API
    const response = await api.getCareGuidesAllIds(partnership_id, filter);
    let success = false;

    // get all care guide ids success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API all care guide ids success.`);

      const data = response.getIn(['data', 'care_guides']);

      const normalizedCareGuideMembersPartnershipEntities = normalize(
        data.map((x) => {
          return { ...x, id: x?.profile?.id };
        }),
        [schema.careGuidesMembersPartnership]
      );

      const normalizedCareGuideEntities = normalize(
        response.getIn(['data', 'care_guides']),
        [schema.careGuide]
      );

      dispatch(addEntities(normalizedCareGuideMembersPartnershipEntities));
      dispatch(addEntities(normalizedCareGuideEntities));

      dispatch(careGuidesAllIdsSuccess(data));
      success = true;
    } else if (204 === response.get('status')) {
      Logger.log('info', `Get API all care guide ids success [empty].`);
      const data = [];
      dispatch(careGuidesAllIdsSuccess(data));
      success = true;

      // get all care guide ids failure
    } else {
      Logger.log('info', `Get API all care guide ids failure.`);
      dispatch(careGuidesAllIdsFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success);
  };
}

export function downloadCareGuidesCSV(
  partnershipId = null,
  name = null,
  payload,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuides.actions] downloadCareGuidesCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuidesCSVDownloadRequest(order, filter));

    // call API
    const response = await api.postCareGuidesCSV(
      partnershipId,
      payload,
      order,
      filter
    );
    let success = false;

    // get care guides CSV success
    if (200 === response.get('status')) {
      Logger.log('info', `Get API care guides CSV success. Order: ${order}`);
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-${name}-members-${Format.date(now, 'YYYY-MM-DDTHHmm')}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuidesCSVDownloadSuccess());
      success = true;

      // get care guides CSV failure
    } else {
      Logger.log('info', `Get API care guides CSV failure.`);
      dispatch(careGuidesCSVDownloadFailure());
    }
    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.careGuides.actions loaded.`);
